
.button_base {
    padding: 10px 20px;
    width: fit-content;
    cursor: pointer;
}

.button_primary {
    @extend .button_base;
    background-color: $primary;
    border-radius: 3px;
    color: white;
    text-decoration: none;

    &:hover {
        color: grey;
    }
     
}

.button_white {
    @extend .button_base;
    background-color: white;
    border-radius: 3px;
    color: $primary;
    text-decoration: none;

    &:hover {
        color: grey;
    }
     
}