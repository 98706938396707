
.productLink_container {
    padding: 5px;
    position: relative;
    z-index: 1;

    p {
        margin: 0;
        // text-align: center;
    }

}

.productLink_white {
    background-color: white;
    cursor: pointer;
    padding: 20px;
    
    &:hover {
        background-color: #00000010;
    }
}

.productLink_comingSoon-container {

    align-items: center;
    background-color: white;
    display: flex;
    justify-content: center;
    padding: 20px;
    height: 30vh;
    
    &:hover {
        background-color: #00000010;
    }

}

.productLink_image {
    width: 75%;
    margin: 0 12.5%;
}

.productLink_name {
    font-weight: bold;
}

.productLink_comingSoon {
    background-color: $primary;
    border-radius: 5px;
    box-shadow: 0 0px 5px #4040406e;
    overflow: hidden;
    position: absolute;
    padding: 0.3rem 25px;
    right: -3px;
    top: 25px;

    &:hover {
        background-color: #ff5454;
    }
}

.productLink_comingSoon-content {
    color: #fff;
    text-transform: uppercase;
    text-align: center;
}

.productLink_price {
    font-weight: light;
    font-size: 0.9rem;
}