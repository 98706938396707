.galeria {
    padding: 5vh 3vw;
}

.galeria h2 {
    margin-bottom: 1rem;
}

.gallery-content {
    margin: auto;
}

.gallery-row {
    display: flex;
    justify-content: space-evenly;
    margin-top: 7px;
}

.gallery-row img {
    flex: 1;
    width: 30%;
    margin-right: 7px;
}

.gallery-row img:last-child {
    margin-right: 0px;
}

.gallery-row img:hover {
    opacity: 0.7;
    cursor: pointer;
}

/*  Gallery On Mobile  */
@media only screen and (max-width: 768px) {


    .gallery-row h2 {
        text-align: center;
    }

    .gallery-row {
        display: block;
    }

    .gallery-row img {
        width: 100%;
    }
}

/* The Modal (background) */
.gallery-modal {
    align-items: center;
    display: flex;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 5;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* Full width */
    overflow: hidden;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/ opacity */
    justify-content: center;
}

/* Modal Content (Image) */
.modal-content {
    margin: auto;
    display: block;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
    margin: auto;
    margin-top: -70px;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}

/* Add Animation - Zoom in the Modal */
.gallery-modal-content,
#caption {
    animation-name: zoom;
    animation-duration: 0.6s;
}

@keyframes zoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

/* The Close Button */
.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
    .gallery-modal-content {
        width: 100%;
    }
}

/* Modal Code End. */