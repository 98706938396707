.preguntas {
    padding: 5vh 3vw;
}

.preguntas-content {
    display: flex;
    padding: 5vh 2vw;
}

.question-container {
    display: block;
    margin-right: 10%;
    width: 45%;
}

.answer-container {
    display: block;
    width: 55%;
}

[data-question] {
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 1rem;
    cursor: pointer;
}

.active[data-question] {
    font-weight: bold;
    text-decoration: underline;
}

[data-answer] {
    display: none;
}

.active[data-answer] {
    display: block;
    font-weight: 400;
    line-height: 1.4rem;
    text-align: justify;
}