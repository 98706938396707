
.home_header {
    background-color: #00000020;
    background-position: center;
    background-size: cover;
    background-blend-mode: multiply;
    min-height: 90vh;
    color: white;
}

.home_header-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 90vh;
}

.home_header-logo {
    max-width: 60%;
}

// Discover electric mobility section.
.home_mobility {
    color: #fff;
    min-height: 90vh;
    max-height: fit-content;
    padding: 10%;
    text-align: center;
}

// Accessories section.
.home_accessories {
    background-color: F8F8F8;
    height: 90vh;
    padding: 10vh 5vw;
    justify-content: center;
}

// Catalog section.
.catalog_container {
    align-items: center;
    color: #fff;
    height: 90vh;
    justify-content: center;
    padding: 10%;
    text-align: center;
}


@media (max-width: $breakpoint-phone) {

    .home_header {
        background-color: #00000060; 
        min-height: auto;
    }


    .home_header-content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: fit-content;
        padding: 15vh 0;
    }


    .home_header-content {
        display: flex;
        justify-content: center;
    }

    .home_header-logo {
        max-width: 75%;
        margin-left: 12.5%;
    }    

    .home_mobility {
        min-height: 75vh;
    }


}