
.bike_name {
    font-weight: bold;
}

.bike_color-container {
    padding: 4px;
    &:hover {
        border: 1px solid rgb(163, 163, 163);
        border-radius: 50%;
        cursor: pointer;
        padding: 3px;
    }
}

.bike_battery-container {
    background-color: rgb(238, 238, 238);
    border-radius: 8px;
    border: 1px solid rgb(238, 238, 238);
    margin: 0 0 10px 0;
    padding: 10px;

    &:hover {
        background-color: white;
        border: 1px solid rgb(238, 238, 238);
        cursor: pointer;
    }

    & p {
        margin: 0;
    }
}

.bike_battery-name {
    font-weight: 500;
}

.bike_battery-description {
    font-size: 0.8rem;
}

.bike_battery-price {
    font-size: 0.9rem;
    font-weight: 600;
}

/* More info item, card similar to spec to select, with info on what that spec is. */
.bike_more-info {
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgb(238, 238, 238);
    margin: 0 0 10px 0;
    padding: 10px;
    
    &:hover {
        background-color: rgb(238, 238, 238);
        border: 1px solid rgb(238, 238, 238);
        cursor: pointer;
    }

    & p {
        margin: 0;
    }
    
    & p.title {
        font-size: 0.8rem;
        font-weight: bold;
    }
    
    & p.description {
        color: rgb(31, 31, 31);
        font-size: 0.7rem;
        font-weight: 300;
    }
}

.bike_active {
    border: 1.5px solid rgb(7, 164, 227);
    background-color: white;
}