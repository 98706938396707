.copyright, .copyright a {
    color: #000;
    font-size: 0.8rem;
    text-decoration: none;
}

.copyright a:hover  {
    text-decoration: underline;
}

.footer {
    background-color: #F3F3F3;
}

.footer .logo {
    width: 70%;
}

.footer p {
    color: black !important;
}

.footer .footer_title {
    color: #009FE3 !important;
    font-size: 1.2rem;
}

.footer_list {
    line-height: 2rem;
    list-style: none;
    padding: 0px;
}

.footer_list a {
    color: black;
    text-decoration: none;
}