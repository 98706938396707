.testimonials_header {
    background-image: url('../../assets/reviews/reviews-header.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: #000;
    color: #FFFFFF;
    padding: 10vw;
    text-align: center;
}

.testimonials_header_links {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: lighter;
}

.testimonials_header_links a {
    color: white;
    cursor: pointer;
}